import { useDigitalAccount, useLocalization } from 'Hooks';
import { FC, Fragment, memo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { colors, fontSizes, gutters } from 'Theme/Variables';
import { ChevronDarkRightIcon, DarkPlusIcon } from 'Components';
import { CardItem } from './CardItem';
import { useHistory } from 'react-router';
import { routePath } from 'Navigator/routes';
import { cardItemParams } from './CardItem/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardTransactionsState,
  resetAddCardStep,
  setInActiveCard,
  storeCardTransactions,
} from 'Stores/CardTransactions';
import { findDigitalCards, filterDigitalCards } from './constant';

import './AllCards.scss';
import { DigitalAccountCard } from 'Components/DigitalAccountCard';
import { VirtualCardItem } from './VirtualCardItem';
import { VirtualCardInformation } from './VirtualCardInformation';

type AllCardsProps = {};

const AllCards: FC<AllCardsProps> = memo(() => {
  const {
    web_title_my_cards,
    web_title_others_card,
    web_btn_add_card,
    web_my_cards_title_mobile,
    web_link_btn_kartlarim_homepage,
    web_cards_connected_to_my_account,
    web_digital_account_title,
  } = useLocalization();

  const history = useHistory();
  const dispatch = useDispatch();

  const cardData: CardTransactionsState = useSelector(storeCardTransactions);
  const digitalAccount = useDigitalAccount();
  const virtualCard = digitalAccount?.cardMasters?.find((card) => card.IsVirtualCard);
  const userCards: cardItemParams[] = cardData?.cards;
  const userOtherCards: cardItemParams[] = cardData?.otherCards;

  const otherCardsIsLoading = cardData?.otherCardStateLoading;

  return (
    <div id="allCards">
      <Typography variant="h6" align="center" className="my__cards-mobileTitle">
        <Box
          fontWeight={600}
          marginLeft={gutters.small}
          textAlign="center"
          fontSize={fontSizes.regularBig}
          marginBottom={gutters.regular}
        >
          {web_link_btn_kartlarim_homepage}
        </Box>
      </Typography>
      <Box className="my__cards-title">
        <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.secondary}>
          {web_digital_account_title}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(resetAddCardStep());
            history.push(routePath.addCard);
          }}
          itemID="addCardBtnTest"
        >
          <DarkPlusIcon />
          <Typography variant="h6" align="center">
            <Box fontWeight={600} marginLeft={gutters.small} fontSize={fontSizes.regularBig}>
              {web_btn_add_card}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={gutters.big}>
        <Grid container spacing={3}>
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
            onClick={() => {
              history.push('/cards/digital-card');
            }}
          >
            <DigitalAccountCard rightSideIcon={<ChevronDarkRightIcon color={colors.digitalTextColor} />} />
          </Grid>
        </Grid>
      </Box>

      <Box className="my__cards-title">
        <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.secondary}>
          {web_cards_connected_to_my_account}
        </Box>
      </Box>
      <Box marginBottom={gutters.big}>
        <Grid container spacing={3}>
          <VirtualCardItem virtualCard={virtualCard} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={4} sm={6} xs={12}>
            <VirtualCardInformation />
          </Grid>
        </Grid>
      </Box>

      {userCards && userCards.length > 0 && !otherCardsIsLoading && (
        <Fragment>
          <Box className="my__cards-title">
            <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.secondary}>
              {web_title_my_cards}
            </Box>
          </Box>
          <Box marginBottom={gutters.big}>
            <Grid container spacing={3}>
              {userCards &&
                filterDigitalCards(userCards)
                  .filter((item) => item.IsPrimary)
                  .map((item: any, index) => (
                    <CardItem
                      cardItem={item}
                      key={index}
                      onClick={() => {
                        dispatch(setInActiveCard(item));
                        history.push(routePath.cardDetails);
                      }}
                      itemID="userCardItemBtnTest"
                    />
                  ))}
              {userCards &&
                filterDigitalCards(userCards)
                  .filter((item) => !item.IsPrimary)
                  .map((item: any, index) => (
                    <CardItem
                      cardItem={item}
                      key={index}
                      onClick={() => {
                        dispatch(setInActiveCard(item));
                        history.push(routePath.cardDetails);
                      }}
                      itemID="userCardItemBtnTest"
                    />
                  ))}
            </Grid>
          </Box>
        </Fragment>
      )}

      {userOtherCards && userOtherCards?.length > 0 && !otherCardsIsLoading && (
        <Fragment>
          <Box className="my__cards-title">
            <Box fontSize={fontSizes.regularBig} fontWeight="bold" color={colors.secondary}>
              {web_title_others_card}
            </Box>
            {userCards && userCards.length === 0 && (
              <Box
                display="flex"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                itemID="addCardBtnTest"
                onClick={() => {
                  dispatch(resetAddCardStep());
                  history.push(routePath.addCard);
                }}
              >
                <DarkPlusIcon />
                <Typography variant="h6" align="center">
                  <Box fontWeight={600} marginLeft={gutters.small} fontSize={fontSizes.regularBig}>
                    {web_btn_add_card}
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>
          <Grid container spacing={3}>
            {userOtherCards &&
              findDigitalCards(userOtherCards).map((item: any, index) => (
                <CardItem
                  cardItem={item}
                  key={index}
                  isUserOtherCard
                  onClick={() => {
                    dispatch(setInActiveCard(item));
                    history.push(routePath.otherCardDetails, { isOtherCard: true });
                  }}
                  itemID="userCardItemBtnTest"
                />
              ))}
            {userOtherCards &&
              filterDigitalCards(userOtherCards).map((item: any, index) => (
                <CardItem
                  cardItem={item}
                  key={index}
                  isUserOtherCard
                  onClick={() => {
                    dispatch(setInActiveCard(item));
                    history.push(routePath.otherCardDetails, { isOtherCard: true });
                  }}
                  itemID="userCardItemBtnTest"
                />
              ))}
          </Grid>
        </Fragment>
      )}
    </div>
  );
});

export default AllCards;
