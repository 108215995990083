import { Box, BoxProps } from '@material-ui/core';
import React from 'react';
import DigitalAccount from 'Assets/Images/cardTypes/digital_account.png';
import { fontSizes, colors } from 'Theme/Variables';
import { useDigitalAccount, useLocalization } from 'Hooks';
import { cardBalanceNormalize } from 'Utils';

import './DigitalAccountCard.scss';

type DigitalAccountCard = BoxProps & {
  cursor?: 'pointer' | 'default';
  rightSideIcon?: React.ReactNode;
  containerStyle?: React.CSSProperties;
};

const DigitalAccountCard: React.FC<DigitalAccountCard> = ({
  cursor = 'pointer',
  rightSideIcon,
  containerStyle,
  ...boxProps
}) => {
  const { web_digital_account_title, web_my_account_total_balance_text } = useLocalization();

  const digitalAccount = useDigitalAccount();

  return (
    <Box id="digitalCard" style={{ cursor }} {...boxProps}>
      <div className={'digitalCard-container'} style={containerStyle}>
        <Box className={`digitalCard-container--image`} display="flex">
          <img src={DigitalAccount} alt="" />
        </Box>
        <Box overflow="hidden" marginLeft={fontSizes.big} flex={1}>
          <Box
            fontWeight={500}
            fontSize={fontSizes.regular}
            color={colors.digitalTextColor}
            style={{ marginBottom: '8px' }}
          >
            {web_digital_account_title}
          </Box>
          <Box fontWeight={400} fontSize={fontSizes.smaller} color={colors.digitalTextColor}>
            {web_my_account_total_balance_text}
          </Box>

          <Box fontSize={fontSizes.regularBig} color={colors.digitalTextColor} fontWeight="700">
            {cardBalanceNormalize(digitalAccount.balanceTotal)} TL
          </Box>
        </Box>
        {rightSideIcon}
      </div>
    </Box>
  );
};

export default DigitalAccountCard;
